import {
  SET_DIALOG,
  SET_DIALOG_CONTENT_PROP,
  RESET_DIALOG_CONTENT_PROP
} from '../constants/ActionTypes';

export const setDialog = ({
  name = '',
  open = false,
  hideClose = false,
  dialogProps = {},
  contentProps = {},
  actions = []
} = {}) => ({
  type: SET_DIALOG,
  name,
  open,
  hideClose,
  dialogProps,
  contentProps,
  actions
});

export const closeDialog = () => setDialog();

export const setDialogProps = (dialogProps = {}) => ({
  type: SET_DIALOG,
  dialogProps
});

export const setDialogContentProps = (contentProps = {}) => ({
  type: RESET_DIALOG_CONTENT_PROP,
  contentProps
});

export const setDialogActions = (actions = []) => ({
  type: SET_DIALOG,
  actions
});

export const setDialogContentProp = key => value => ({
  type: SET_DIALOG_CONTENT_PROP,
  key,
  value
});

export const setDialogComment = (
  action,
  contentProps,
  dialogProps = {},
  name
) => setDialog({
  name: 'CommentDialog',
  open: true,
  contentProps,
  dialogProps,
  actions: [
    {
      children: name || 'Commenter',
      onClick: action
    }
  ]
});
