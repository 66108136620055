import store from '../store';

export function formatStr(num) {
  return num < 10 ? `0${num}` : num;
}

export const genId = (firstChar, length = 6) =>
  firstChar + Math.random().toString(36).toUpperCase().substr(2, length);

export const genRandomId = (firstChar, length = 6, retryLimit = 5) => {
  const products = store.getState().products.array;
  const ids = products.map(e => e.ref);
  let retryCounter = retryLimit;
  while (retryCounter > 0) {
    const r = genId(firstChar, length);
    if (ids.includes(r)) {
      retryCounter--;
    } else {
      return r;
    }
  }
  return null;
};
