import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TableCell from '@mui/material/TableCell';
import CustomTextField from '../../CustomInputs/CustomTextField';
import { withMediaQuery } from '../../../hoc';

const InputProps = {
  inputProps: { min: '1' }
};

class ProductQte extends PureComponent {

  inputQT(qt, path, setData) {
    return (
      <CustomTextField
        type='number'
        value={qt}
        InputProps={InputProps}
        path={path}
        setData={setData}
        name={'qt'}
        multiline={false}
      />
    );
  }

  render() {
    const { qt, path, setData } = this.props;
    return (
      !this.props.isDown.md ?
        <TableCell padding="checkbox" align={'right'}>
          { this.inputQT(qt, path, setData) }
        </TableCell> :
        this.inputQT(qt, path, setData)
    );
  }
}

ProductQte.defaultProps = {
  qt: 0
};

ProductQte.propTypes = {
  qt: PropTypes.number.isRequired,
  path: PropTypes.arrayOf(PropTypes.string).isRequired,
  setData: PropTypes.func.isRequired
};

export default withMediaQuery()(ProductQte);
