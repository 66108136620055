import React from 'react';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {
  CHEQUE,
  data as paymentMethods,
  ESP,
} from '@lba-dev/package.local-globals/paymentMethods';

import Selector from '../../Utils/Selector';
import MoneyInput from '../../CustomInputs/MoneyInput';
import CustomSwitch from '../../CustomInputs/CustomSwitch';
import CustomTextField from '../../CustomInputs/CustomTextField';
import CustomRadioGroup from '../../CustomInputs/CustomRadioGroup';
import Attachments from '../../Utils/Attachments';
import {
  data as lateReasons
} from '@lba-dev/package.local-globals/lateReasons';
import DatePicker from '../../DatePicker';

const repairsType = [
  { label: 'Non réparé', value: '0' },
  { label: 'Réparé définitivement', value: '1' },
  { label: 'Réparé partiellement', value: '2' },
];

const paymentMethodsSelect = paymentMethods.map((e) => (
  <MenuItem
    key={e._id}
    value={e._id}
    children={e.fullName}
    disabled={['Facture à envoyer', 'Virement', 'Aucun'].includes(e.fullName)}
  />
));

const lateReasonsSelect = lateReasons.map((e) => (
  <MenuItem key={e._id} value={e._id} children={e.name} />
));

const Content = ({
  showOnlyPrice,
  applyTva,
  price,
  paymentChoice,
  additionnalInfos,
  setPrice,
  toggleTva,
  setPaymentChoice,
  setAdditionnalInfos,
  attachments,
  addFile,
  productsSum,
  deleteFile,
  lateReason,
  setLateReason,
  dateIntervention,
  setDateIntervention,
  repairType,
  setRepairType,
}) => (
  <>
    {!showOnlyPrice && (
      <React.Fragment>
        <Grid item xs={12}>
          <Typography children="Quel est le mode de règlement ?" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Selector
            title={
              paymentChoice === ESP
                ? 'Un signalement sera émis'
                : 'Mode de règlement'
            }
            error={paymentChoice === ESP}
            value={paymentChoice}
            handleChange={setPaymentChoice}
            children={paymentMethodsSelect}
          />
        </Grid>
      </React.Fragment>
    )}
    <Grid item xs={12} sm={6}>
      <Selector
        title={'Horaire'}
        value={lateReason}
        handleChange={setLateReason}
        children={lateReasonsSelect}
      />
    </Grid>
    <Grid item xs={12}>
      <Typography
        children={`Quel est le montant final${
          !showOnlyPrice && [ESP, CHEQUE].includes(paymentChoice)
            ? ' récupéré '
            : ' de l\'intervention'
        }? `}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <MoneyInput
        disabled={productsSum && price === productsSum}
        error={!price || (productsSum && productsSum !== price)}
        label="Montant final"
        defaultValue={price}
        setData={(p, v) => setPrice(v)}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <CustomSwitch
        disabled={productsSum && price === productsSum}
        label={applyTva ? 'TTC' : 'HT'}
        value={applyTva}
        setData={toggleTva}
      />
    </Grid>
    {dateIntervention ? (
      <>
        <Grid item xs={12}>
          <Typography children={'Confirmez-vous la date d\'intervention ?'} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            before={true}
            label={'Date d\'intervention'}
            startDate={new Date(dateIntervention)}
            onChange={(v) => setDateIntervention(v)}
          />
        </Grid>
      </>
    ) : (
      ''
    )}
    <CustomRadioGroup
      grid
      xs={12}
      sm={6}
      md={12}
      label="Etat de la réparation"
      value={repairType.toString()}
      values={repairsType}
      setData={(p, v) => setRepairType(Number(v))}
    />
    <Grid item xs={12}>
      <CustomTextField
        label="Renseignements complémentaires"
        rows={5}
        texttransform=" "
        placeholder={`- La raison de la panne ou fuite
      - La solution a apporter au client
      - Si devis complémentaire à faire`}
        value={additionnalInfos}
        setData={(p, v) => setAdditionnalInfos(v)}
      />
    </Grid>
    <Attachments data={attachments} onAdd={addFile} onDelete={deleteFile} />
  </>
);

export default Content;
