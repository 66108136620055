import React, { PureComponent } from 'react';
import { attachFile } from '@lba-dev/package.local-globals/utils';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Footer from './Footer';
import { defaultText } from './defaultText';
import { withStyles } from 'tss-react/mui';
import {
  getSize,
  isMaxSizeOk,
  isMaxFileOk,
  MAX_FILES_SIZE,
  MAX_FILES,
} from '../../../utils/function';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import notifSystem from '../../../notifSystem';

const styles = {
  TextInput: {
    overflow: 'auto',
    maxHeight: 430,
    minHeight: 200,
    height: 435
  }
};
class Content extends PureComponent {
  state = {
    multiline: defaultText(this.props.data),
    files: [],
    loading: false,
    totalSize: 0,
    maxSize: MAX_FILES_SIZE,
    maxFile: MAX_FILES,
    isSizeOk: true,
    isFileOk: true,
    sendMail: true,
    sendSMS: true
  }

  send = () => {
    const { isFileOk, isSizeOk, files,
      sendSMS, sendMail, multiline } = this.state;
    if (!sendSMS && !sendMail) {
      return notifSystem.error('Erreur', 'Veuillez cocher un mode d\'envoi');
    }
    if (multiline.match('__BUDGET__') && sendMail) {
      return notifSystem.error('Erreur', 'Vous n\'avez pas défini de budget');
    }
    if (isFileOk && isSizeOk) {
      this.props.callback(sendSMS,
        sendMail ? { mail: multiline, files } : '');
    }
  }

  cancel = () => {
    this.props.callback();
  }

  addFile = (e) => {
    attachFile(e.target.files[0]).then(attachment => {
      const ret = this.state.files.concat(attachment);
      this.setState({
        files: ret,
        totalSize: getSize(ret),
        isFileOk: isMaxFileOk(ret.length, this.state.maxFile),
        isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
      });
    });
  }

  handleChange = name => event => {
    this.setState({
      [name]: ['sendMail', 'sendSMS'].includes(name) ?
        !this.state[name] : event.target.value
    });
  }

  handleChipDelete = (index) => {
    this.state.files.splice(index, 1);
    const ret = this.state.files.slice(0);
    const tmpSize = getSize(ret);
    this.setState({
      files: ret,
      totalSize: tmpSize,
      isFileOk: isMaxFileOk(ret.length, this.state.maxFile),
      isSizeOk: isMaxSizeOk(ret, this.state.maxSize)
    });
  }

  render() {
    const attachments = 'Pièces jointes';
    const {
      multiline,
      totalSize,
      maxFile,
      maxSize,
      files,
      isFileOk,
      isSizeOk,
      loading,
      sendMail
    } = this.state;
    const up = isFileOk ? (
      <Typography variant="caption">
        {attachments}: {Object.keys(files).length}/{maxFile}</Typography>)
      : (<Typography variant="caption" style={{ color: 'red' }}>
        {attachments}: {Object.keys(files).length}/{maxFile}</Typography>);

    const down = isSizeOk ? (
      <Typography variant="caption">{totalSize}/{maxSize}</Typography>)
      : (
        <Typography variant="caption" style={{ color: 'red' }}>
          {totalSize}/{maxSize}
        </Typography>
      );
    return (
      <Card style={{ overflow: 'auto' }}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid key="switch" item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.sendMail}
                    onChange={this.handleChange('sendMail')}
                    value="checkedMail"
                  />
                }
                label="Envoyer le mail"
              />
            </Grid>
            <Grid key="switch" item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.sendSMS}
                    onChange={this.handleChange('sendSMS')}
                    value="checkedSMS"
                  />
                }
                label="Envoyer le SMS"
              />
            </Grid>
          </Grid>
        </CardContent>
        {sendMail ? <CardContent>
          <TextField
            variant="standard"
            fullWidth
            id="textarea"
            multiline
            value={multiline}
            onContextMenu={(e) => { e.stopPropagation(); }}
            InputProps={{ style: styles.TextInput }}
            rows={30}
            onChange={this.handleChange('multiline')}
            label="Corps du mail" />
        </CardContent> : ''}
        <CardContent style={{ 'minHeight': '100px' }}>
          {up}
          <CardContent>
            {Object.values(files).map((element, index) => (
              <Chip
                key={index}
                label={element.name}
                onDelete={() => this.handleChipDelete(index)}
              />
            ))}
          </CardContent>
          <div style={{ 'float': 'right' }}>
            {down}
          </div>
        </CardContent>
        <Footer key = "footer"
          send={this.send}
          cancel={this.cancel}
          addFile={this.addFile}
          loading={loading}
        />
      </Card>
    );
  }
}

export default withStyles(Content, styles);
